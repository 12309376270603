import { OfficeModel } from '../models';

const FALCON_OFFICE_CODE_PREFIX = 'FAL';

export const falconOfficesFirstSorter = (offices: OfficeModel[]): OfficeModel[] => {
  return offices.sort(({ shortName: officeCode1 }, { shortName: officeCode2 }) =>
    officeCode1.startsWith(FALCON_OFFICE_CODE_PREFIX) &&
    !officeCode2.startsWith(FALCON_OFFICE_CODE_PREFIX)
      ? -1
      : officeCode1.localeCompare(officeCode2)
  );
};
