import { FalconEnvEnum } from './falcon-env.enum';

const BOEMM_LOGIN_PARAMS = {
  identity_provider: 'BoemmAD',
  client_id: '414iqgk4s5hqdb92fe5gs4forq',
  response_type: 'code',
  scope: ['aws.cognito.signin.user.admin', 'email', 'openid', 'phone', 'profile'].join(' '),
  redirect_uri: 'https://gw.dev.falcon.boemm.eu/v1/signin',
};

const ITSME_LOGIN_PARAMS = {
  identity_provider: 'ItsMeLogin',
  client_id: '414iqgk4s5hqdb92fe5gs4forq',
  response_type: 'code',
  scope: ['aws.cognito.signin.user.admin', 'email', 'openid', 'phone', 'profile'].join(' '),
  redirect_uri: 'https://gw.dev.falcon.boemm.eu/v1/signin',
};

const ITSME_REGISTER_PARAMS = {
  identity_provider: 'ItsMeRegistration',
  client_id: '414iqgk4s5hqdb92fe5gs4forq',
  response_type: 'code',
  scope: ['aws.cognito.signin.user.admin', 'email', 'openid', 'phone', 'profile'].join(' '),
  redirect_uri: 'https://gw.dev.falcon.boemm.eu/v1/signin',
};

const boemmLoginParamsStr = new URLSearchParams(BOEMM_LOGIN_PARAMS).toString();
const itsmeLoginParamsStr = new URLSearchParams(ITSME_LOGIN_PARAMS).toString();
const itsmeRegisterParamsStr = new URLSearchParams(ITSME_REGISTER_PARAMS).toString();

export const environment = {
  name: FalconEnvEnum.DEV,
  production: true,
  appVersion: require('../../package.json').version,
  apiURL: 'https://gw.dev.falcon.boemm.eu/v1/falcon-api/api',
  publicApiURL: 'https://gw.dev.falcon.boemm.eu/v1/falcon-api/publicapi',
  notificationsApiUrl: 'https://gw.dev.falcon.boemm.eu/v1/falcon-api',
  boemmLoginURL: `https://falcon-dev-v2.auth.eu-central-1.amazoncognito.com/oauth2/authorize?${boemmLoginParamsStr}`,
  itsMeLoginURL: `https://falcon-dev-v2.auth.eu-central-1.amazoncognito.com/oauth2/authorize?${itsmeLoginParamsStr}`,
  itsMeRegisterURL: `https://falcon-dev-v2.auth.eu-central-1.amazoncognito.com/oauth2/authorize?${itsmeRegisterParamsStr}`,
  baseUrl: 'https://dev.falcon.boemm.eu',
  gtmId: 'GTM-PWJGF5P',
  featureFlagClientId: '64b55462630de413995730de',
  iosAppStoreUrl: 'https://apps.apple.com/app/id324684580', // Since only prod app will be available in AppStore, Spotify app is used for testing
  androidGooglePlayUrl: 'https://play.google.com/store/apps/details?id=com.spotify.music', // Since dev app is not available in Google Play, Spotify app used for testing,
};
