import { ChangeDetectionStrategy, Component } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { FeatureFlagKeysEnum, FeatureFlagService } from '@falcon/shared/services';
import { AsyncPipe } from '@angular/common';

@Component({
  standalone: true,
  selector: 'falcon-dps-footer-info',
  templateUrl: './dps-footer-info.component.html',
  styleUrls: ['./dps-footer-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [TranslateModule, AsyncPipe],
})
export class DpsFooterInfoComponent {
  constructor(
    private featureFlagService: FeatureFlagService
  ) {}

  readonly showNewLegalText$ = this.featureFlagService.getFlag$<boolean>(
    FeatureFlagKeysEnum.USE_NEW_WT_LEGAL_TEXT
  )
}
